<template>
  <section class="bg-[#010104] h-[93vh] md:h-screen overflow-hidden relative">
    <div ref="text" class="container mx-auto h-full relative z-10">
      <div class="flex w-full pt-28 sm:pt-0 h-4/5 relative">
        <div
          class="banner-text w-full md:w-[60%] mb-0 mt-auto md:my-auto md:pt-20 z-[1]"
        >
          <div
            class="text-white text-sm sm:text-base mb-3 md:mb-5"
            v-html="banners?.attributes?.banner?.description"
          ></div>
          <h1 class="banner-heading mb-5 md:mb-8">
            {{ banners?.attributes?.banner?.title }}
          </h1>

          <NuxtLink
            :to="banners?.attributes?.banner?.cta_url"
            :title="banners?.attributes?.banner?.cta_wording"
            class="px-3 py-[10px] sm:py-3 sm:px-5 text-white border border-white rounded-md block w-max text-sm sm:text-base font-medium hover:bg-white hover:text-black-900"
            >{{ banners?.attributes?.banner?.cta_wording }}</NuxtLink
          >
        </div>
      </div>
      <div
        class="flex gap-0 relative z-[2] md:mx-0 sm:justify-normal mt-10 md:mt-8"
      >
        <template v-if="loadingInfographics">
          <div
            v-for="i in 2"
            :key="i"
            class="stats flex flex-col text-white px-2 sm:px-5 lg:px-7 xl:px-13 first:pl-0 border-r last:border-r-0 border-[#C1C1C1] border-opacity-60"
          >
            <UtilsSkeletonLoading class="w-14 sm:w-20 h-10 mb-2" />
            <UtilsSkeletonLoading class="w-24 sm:w-36 h-7" />
          </div>
        </template>
        <template v-else>
          <div
            class="stats flex flex-col text-white px-2 sm:px-5 lg:px-7 xl:px-13 first:pl-0 border-r last:border-r-0 border-[#C1C1C1] border-opacity-60"
          >
            <span
              class="font-medium text-lg md:text-[40px] md:leading-[50px] md:mb-3"
              >{{ currentYear - 2009 }}+</span
            >
            <span class="text-xs leading-[16px] md:text-base opacity-60"
              >Years of Excellence</span
            >
          </div>
          <div
            v-for="item in infographics?.attributes?.infographics"
            :key="item.id"
            class="stats flex flex-col text-white px-2 sm:px-5 lg:px-7 xl:px-13 first:pl-0 border-r last:border-r-0 border-[#C1C1C1] border-opacity-60"
          >
            <span
              class="font-medium text-lg md:text-[40px] md:leading-[50px] md:mb-3"
              >{{ item.number }}+</span
            >
            <span class="text-xs leading-[16px] md:text-base opacity-60">{{
              item.description
            }}</span>
          </div>
        </template>
      </div>
    </div>
    <div ref="overlay" class="home-overlay md:hidden"></div>
    <div ref="sky" class="min-h-screen w-max xl:w-screen absolute top-0">
      <NuxtImg
        class="banner-bg-image w-full min-h-screen"
        format="webp"
        :src="bgImage"
        alt="Sky"
        title="Sky"
      ></NuxtImg>
    </div>
    <NuxtImg
      format="webp"
      srcset=""
      :src="image.url"
      :alt="image.name"
      :title="image.name"
      class="mx-auto banner-image"
    />
  </section>
</template>

<script setup>
import { useGlobalStore } from '~/store/global'

const global = useGlobalStore()

const loadingInfographics = ref(true)
const banners = ref({})
const infographics = ref({})

const currentYear = ref(new Date().getFullYear())
banners.value = global.mainBanner

const getInfographics = async () => {
  const { data, pending, error } = await useSuitmediaApiData(
    `/api/infographic`,
    {
      query: {
        populate: 'infographics'
      },
      retry: 3,
      retryDelay: 300
    }
  )
  loadingInfographics.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Infographics does not exist`
    })
  }

  return data.value
}

const res = await getInfographics()
infographics.value = res.data

const bgImage = computed(() => {
  return banners.value.attributes?.banner?.background_image?.data?.attributes
    ?.url
})
const image = computed(() => {
  return banners.value.attributes?.banner?.image?.data?.attributes
})

const sky = ref(null)
const text = ref(null)
const overlay = ref(null)

onMounted(() => {
  lazyScroll(sky.value, 1)
  lazyScroll(text.value, -2)
  lazyScroll(overlay.value, 0)
})
</script>

<style lang="scss" scoped>
.home-overlay {
  background: linear-gradient(180deg, rgba(14, 16, 22, 0) 0%, #0e1016 74.31%);
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0;
  z-index: 1;
}
.banner-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  aspect-ratio: 16 / 9;
}
.banner-image {
  @apply absolute right-0 max-md:left-0 bottom-0 object-cover;

  height: calc(100vh - 66px - 0px);
  aspect-ratio: 1 / 2;
}
</style>
